import { homePageUrl } from '../constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function composeCssClassName(str?: string) {
  if (str) {
    const className = str?.replace(
      /\.?([A-Z])/g,
      function (x: string, y: string) {
        return '-' + y.toLowerCase();
      }
    );
    return className;
  }
  return '';
}

export function uuidv4() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function isDevelopmentEnvironment() {
  const env = process.env.NODE_ENV;
  if (env == 'development') {
    return true;
  } else {
    return false;
  }
}

export function convertObjectToArray(obj: any): any[] {
  return Object.entries(obj ?? {}).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value?.map((element, index) => element);
    } else {
      return value;
    }
  });
}

export function camelizeString(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function isDateStringValid(dateString: string) {
  const date = new Date(dateString);
  return date.toString() !== 'Invalid Date';
}

export function formatDate(dateStr: string) {
  const isValidDate = isDateStringValid(dateStr);
  if (dateStr && isValidDate) {
    const date = new Date(dateStr);
    const month = date.toLocaleDateString('en-US', {
      month: 'long'
    });
    const year = date.toLocaleDateString('en-US', {
      year: 'numeric'
    });
    const day = date.toLocaleDateString('en-US', {
      day: '2-digit'
    });
    const dateFormatted = `${day}, ${month} ${year}`;
    return dateFormatted;
  } else {
    return '';
  }
}

export function isLocalOfficeLocationUrl(url: string): boolean {
  const urlParts = url?.split('/');
  // Check if the URL has at least two parts and the second part is 'locations'
  return urlParts?.length >= 2 && urlParts[1] === 'locations';
}

export function getPriorityItem(data: Array<any>, field: string) {
  if (!data || !field) {
    return null;
  }
  return data?.reduce(
    (min, p) => (p[`${field}`] < min[`${field}`] ? p : min),
    data[0]
  );
}

export function isValidUSPhoneNumber(phoneNumber: string): boolean {
  const phoneRegex = /^(?:\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
  return phoneRegex.test(phoneNumber);
}

export function delayRender(promise: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, 300);
  }).then(() => promise);
}

// To get the nested slug of a contentful page (Parent page approach)
export const getNestedSlug = (obj: any) => {
  const slugs: string[] = []; // Array to store slugs
  function traverse(obj: any) {
    // If current object has slug, push it to the slugs array
    if (obj?.fields?.slug && obj?.fields?.slug !== homePageUrl) {
      slugs.push(obj?.fields?.slug);
    }
    // If current object has parentPage property, recursively call traverse function
    if (obj?.fields?.parentPage) {
      traverse(obj?.fields?.parentPage);
    }
  }
  traverse(obj);
  return slugs?.reverse()?.join('/');
};

export function getUserAgent() {
  if (typeof navigator !== 'undefined') {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
      // } else if (
      //   navigator.userAgent.indexOf('MSIE') != -1 ||
      //   !!document?.DOCUMENT_NODE == true
      // ) {
      //   //IF IE > 10
      //   return 'IE';
    } else {
      return 'unknown';
    }
  }
}
